import React, { useState, useContext, useEffect } from "react";
import { Form, Badge } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import Input from './common/Input';
import { AuthContext } from '../context/auth-context';
import { CoreContext } from '../context/core-context';
import { useNavigate } from 'react-router-dom';

const AddUser = (props) => {

    const authContext = useContext(AuthContext);
    const coreContext = useContext(CoreContext);
    const navigate = useNavigate();

    const redirect = () => {
        if (authContext.userId !== authContext.itemId) { navigate('/'); alert('Action Not Authorized !'); }
    }

    useEffect(redirect, []);

    const { register, handleSubmit, errors } = useForm({});
    // const onSubmit = data => console.log(data);

    const roleOptions = () => {
        coreContext.fetchRoleOptions();
    }

    useEffect(roleOptions, []);

    const signUpHandler = (data) => {
        const { name, email, password, role, contact1, contact2 } = data;
        authContext.addUser(name, email, password, role, contact1, contact2);

    }



    return (
        <div className='container'>
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                    <div className="card">
                        <div className="card-title mx-auto"><h3><Badge variant="success">Sign Up A New User</Badge></h3></div>
                        <div className="card-body">
                            <Form autoComplete='off' onSubmit={handleSubmit(signUpHandler)} noValidate>
                                <Input label='Name' name='name' minLength={5} maxLength={55} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Name' />


                                <Input label='Email' name='email' required={true} register={register} errors={errors} elementType='email' minLength={5} maxLength={55} pattern={/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/} placeholder='Enter Email' />


                                <Input label='Password' required={true} register={register} errors={errors} name='password' minLength={5} maxLength={55} elementType='password' placeholder='Enter Password' />

                                <Input label='Contact-I' name='contact1' minLength={10} maxLength={10} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Contact No 1.' />

                                <Input label='Contact-II' name='contact2' minLength={10} maxLength={10} required={false} register={register} errors={errors} elementType='text' placeholder='Enter Contact No 2.' />

                                <Input label='Role' name='role' required={true} register={register} errors={errors} elementType='select' options={coreContext.roleOptions} />


                                <Input elementType='button' variant='primary' /> &nbsp;&nbsp; {authContext.ren}
                                <Input variant='danger' label={authContext.message} elementType='label' />
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}



export { AddUser };