/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import Input from './common/Input';
import { Badge, Form } from 'react-bootstrap';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { useForm } from "react-hook-form";


const FunFacts = () => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);
    const [firstNumber, setFirstNumber] = useState('');
    const [secondNumber, setSecondNumber] = useState('');
    const [thirdNumber, setThirdNumber] = useState('');
    const [fourthNumber, setFourthNumber] = useState('');
    const [firstLabel, setFirstLabel] = useState('');
    const [secondLabel, setSecondLabel] = useState('');
    const [thirdLabel, setThirdLabel] = useState('');
    const [fourthLabel, setFourthLabel] = useState('');
    const [firstIcon, setFirstIcon] = useState('');
    const [secondIcon, setSecondIcon] = useState('');
    const [thirdIcon, setThirdIcon] = useState('');
    const [fourthIcon, setFourthIcon] = useState('');


    const fetchClient = () => {
        coreContext.fetchSelfEnrollToday(authContext.itemId);
    }

    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });

    useEffect(fetchClient, []);

    const populateClient = () => {
        setFirstNumber(coreContext.client.ffno1);
        setSecondNumber(coreContext.client.ffno2);
        setThirdNumber(coreContext.client.ffno3);
        setFourthNumber(coreContext.client.ffno4);
        setFirstLabel(coreContext.client.fflabel1);
        setSecondLabel(coreContext.client.fflabel2);
        setThirdLabel(coreContext.client.fflabel3);
        setFourthLabel(coreContext.client.fflabel4);
        setFirstIcon(coreContext.client.fficon1);
        setSecondIcon(coreContext.client.fficon2);
        setThirdIcon(coreContext.client.fficon3);
        setFourthIcon(coreContext.client.fficon4);
    }

    useEffect(populateClient, [coreContext.client]);

    const onFirstLabelChangedHandler = (e) => {
        setFirstLabel(e.target.value);
    }
    const onFirstNumberChangedHandler = (e) => {
        setFirstNumber(e.target.value);
    }
    const onFirstIconChangedHandler = (e) => {
        setFirstIcon(e.target.value);
    }
    const onSecondLabelChangedHandler = (e) => {
        setSecondLabel(e.target.value);
    }
    const onSecondNumberChangedHandler = (e) => {
        setSecondNumber(e.target.value);
    }
    const onSecondIconChangedHandler = (e) => {
        setSecondIcon(e.target.value);
    }
    const onThirdLabelChangedHandler = (e) => {
        setThirdLabel(e.target.value);
    }
    const onThirdNumberChangedHandler = (e) => {
        setThirdNumber(e.target.value);
    }
    const onThirdIconChangedHandler = (e) => {
        setThirdIcon(e.target.value);
    }
    const onFourthLabelChangedHandler = (e) => {
        setFourthLabel(e.target.value);
    }
    const onFourthNumberChangedHandler = (e) => {
        setFourthNumber(e.target.value);
    }
    const onFourthIconChangedHandler = (e) => {
        setFourthIcon(e.target.value);
    }
    const onFFSectionHandler = (data) => {
        coreContext.updateFFSection(firstNumber, secondNumber, thirdNumber, fourthNumber, firstLabel, secondLabel, thirdLabel, fourthLabel, firstIcon, secondIcon, thirdIcon, fourthIcon, authContext.itemId);

    }

    return (<div className='container-fluid'>
        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Fun Facts Section</Badge></h3></div>
            <div className="card-body">
                <Form autoComplete='off' onSubmit={handleSubmit(onFFSectionHandler)} noValidate>

                    <div className="row">
                        <div className="col-md-4">
                            <Input label='First Icon' value={firstIcon} name='firsticon' minLength={1} maxLength={255} required={false} register={register} errors={errors} elementType='text' placeholder='Enter First Icon' onChange={onFirstIconChangedHandler} />
                        </div>
                        <div className="col-md-4">
                            <Input label='First Number' value={firstNumber} name='firstnumber' minLength={1} maxLength={55} required={false} register={register} errors={errors} elementType='text' placeholder='Enter First Number' onChange={onFirstNumberChangedHandler} />
                        </div>
                        <div className="col-md-4">
                            <Input label='First Label' value={firstLabel} name='firstlabel' minLength={1} maxLength={55} required={false} register={register} errors={errors} elementType='text' placeholder='Enter First Label' onChange={onFirstLabelChangedHandler} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <Input label='Second Icon' value={secondIcon} name='secondicon' minLength={1} maxLength={255} required={false} register={register} errors={errors} elementType='text' placeholder='Enter Second Icon' onChange={onSecondIconChangedHandler} />
                        </div>
                        <div className="col-md-4">
                            <Input label='Second Number' value={secondNumber} name='secondnumber' minLength={1} maxLength={55} required={false} register={register} errors={errors} elementType='text' placeholder='Enter Second Number' onChange={onSecondNumberChangedHandler} />
                        </div>
                        <div className="col-md-4">
                            <Input label='Second Label' value={secondLabel} name='secondlabel' minLength={1} maxLength={55} required={false} register={register} errors={errors} elementType='text' placeholder='Enter Second Label' onChange={onSecondLabelChangedHandler} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <Input label='Third Icon' value={thirdIcon} name='thirdicon' minLength={1} maxLength={255} required={false} register={register} errors={errors} elementType='text' placeholder='Enter Third Icon' onChange={onThirdIconChangedHandler} />
                        </div>
                        <div className="col-md-4">
                            <Input label='Third Number' value={thirdNumber} name='thirdnumber' minLength={1} maxLength={55} required={false} register={register} errors={errors} elementType='text' placeholder='Enter Third Number' onChange={onThirdNumberChangedHandler} />
                        </div>
                        <div className="col-md-4">
                            <Input label='Third Label' value={thirdLabel} name='thirdlabel' minLength={1} maxLength={55} required={false} register={register} errors={errors} elementType='text' placeholder='Enter Third Label' onChange={onThirdLabelChangedHandler} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <Input label='Fourth Icon' value={fourthIcon} name='fourthicon' minLength={1} maxLength={255} required={false} register={register} errors={errors} elementType='text' placeholder='Enter Fourth Icon' onChange={onFourthIconChangedHandler} />
                        </div>
                        <div className="col-md-4">
                            <Input label='Fourth Number' value={fourthNumber} name='fourthnumber' minLength={1} maxLength={55} required={false} register={register} errors={errors} elementType='text' placeholder='Enter Fourth Number' onChange={onFourthNumberChangedHandler} />
                        </div>
                        <div className="col-md-4">
                            <Input label='Fourth Label' value={fourthLabel} name='fourthlabel' minLength={1} maxLength={55} required={false} register={register} errors={errors} elementType='text' placeholder='Enter Fourth Label' onChange={onFourthLabelChangedHandler} />
                        </div>
                    </div>

                    <Input elementType='button' variant='primary' value="Update" /> &nbsp;&nbsp; {coreContext.renderLoader()}
                    <Input variant='danger' label={coreContext.message} elementType='label' />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a target='_blank' href="https://fontawesome.com/icons?d=gallery">Find Icons</a>
                </Form>
            </div>
        </div>

    </div>
    )
}

export { FunFacts }