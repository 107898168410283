/* eslint-disable react/no-danger-with-children */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { Line, XSquare } from 'react-bootstrap-icons';
import { Badge, Form } from 'react-bootstrap';
import Input from './common/Input';
import ImageUploader from './common/UploadImage';


const Client = (props) => {
    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);
    const [videoLink, setVideoLink] = useState('');
    const [maxImagesAllowed, setMaxImagesAllowed] = useState(6);
    const [hasImages, setHasImages] = useState(false);
    const [hideMenu, setHideMenu] = useState('no');
    const [hideFooter, setHideFooter] = useState('no');
    const [images, setImages] = useState([]);
    const [videos, setVideos] = useState([]);
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
    const [videoUploadButtonDisabled, setVideoUploadButtonDisabled] = useState(true);
    const [selectedImages, setSelectedImages] = useState([]);
    const [hasVideos, setHasVideos] = useState(false);
    const [hasRatings, setHasRatings] = useState(false);
    const [statusOptions] = useState([{ name: 'Active', value: 'active' }, { name: 'Inactive', value: 'inactive' }]);
    const [isCollegeOptions] = useState([{ name: 'Yes', value: 'yes' }, { name: 'No', value: 'no' }]);


    //useEffect(setClient(coreContext.client), [client]);

    const location = useLocation();

    const fetchClient = () => {
        coreContext.fetchClient(location, authContext.itemId);
    }

    useEffect(fetchClient, []);


    const loadLoactionOptions = () => {
        coreContext.fetchCountries();
        if (coreContext.client.countryId)
            coreContext.fetchStates(coreContext.client.countryId);
        // else
        //     coreContext.fetchStates(1);

        if (coreContext.client.stateId)
            coreContext.fetchDistricts(coreContext.client.countryId, coreContext.client.stateId);
        if (coreContext.client.id)
            coreContext.fetchLocalAreas(coreContext.client.id);
    }

    const deleteImage = (id) => {
        const newImages = images.filter(image => image.id !== id);
        setImages(newImages);
        coreContext.deleteImage(id);
    }

    const deleteVideo = (id) => {
        const newVideos = videos.filter(video => video.id !== id);
        setVideos(newVideos);
        coreContext.deleteVideo(id);
    }

    const populateClient = () => {
        console.log(coreContext.client);
        setValue('name', coreContext.client.item_name);
        setValue('contactName', coreContext.client.item_contact_person);
        setValue('tag', coreContext.client.item_tag);
        setValue('loc', coreContext.client.item_loc);
        setValue('email', coreContext.client.item_email);
        setValue('contact1', coreContext.client.item_mobile_1);
        setValue('contact2', coreContext.client.item_mobile_2);
        setValue('contact', coreContext.client.item_contact);
        setValue('address', coreContext.client.item_address);
        setValue('description', coreContext.client.item_description);
        setValue('pincode', coreContext.client.item_pincode);
        setValue('state', coreContext.client.stateId + '');
        setValue('district', coreContext.client.districtId);
        setValue('country', coreContext.client.countryId + '');
        setImages(coreContext.client.images);
        setVideos(coreContext.client.video_links);


        setValue('erp_branchid', coreContext.client.erp_branchid);
        setValue('is_college', coreContext.client.is_college);
        setValue('localarea', coreContext.client.areaId);
        setValue('category', coreContext.client.categoryId);
        setValue('status', coreContext.client.item_status);
        setValue('site_url', coreContext.client.site_url);
        setValue('hide_menu', coreContext.client.hide_menu);
        setHideMenu(coreContext.client.hide_menu);
        setValue('hide_footer', coreContext.client.hide_footer);
        setHideFooter(coreContext.client.hide_footer);
        if (coreContext.client.has_images === 'no')
            setHasImages(false);
        else setHasImages(true);
        if (coreContext.client.has_videos === 'no')
            setHasVideos(false);
        else
            setHasVideos(true);
        if (coreContext.client.has_ratings === 'no')
            setHasRatings(false);
        else
            setHasRatings(true);

    }

    useEffect(loadLoactionOptions, [coreContext.client]);
    useEffect(populateClient, [coreContext.client]);

    const renderImages = () => {
        if (images)
            return images.map(image => <div className="col-md-6"><img style={{ padding: 5 }} className="img-fluid" src={`http://${image.url}`} alt='gallery image' /> <button onClick={() => deleteImage(image.id)} style={{ position: 'absolute', top: 0, right: 15 }} type="button" className="btn btn-danger btn-sm" title="Delete Image"> <XSquare size="12" /> </button></div>)
    }

    const renderVideos = () => {
        //console.log(coreContext.client);
        if (videos)
            return videos.map(video => <div className="col-md-12"> <div dangerouslySetInnerHTML={{ __html: video.url }} /> <button onClick={() => deleteVideo(video.id)} style={{ position: 'absolute', top: 0, right: 15 }} type="button" className="btn btn-danger btn-sm" title="Delete Video"> <XSquare size="12" /> </button></div>)
    }


    const { register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues } = useForm();


    const clientEditHandler = (data) => {
        const { name, email, address, contact1, contact2, contactName, tag, loc, contact, description, pincode, localarea, state, district, category, country, status, site_url, hide_menu, hide_footer, erp_branchid, is_college } = data;

        coreContext.editClient(coreContext.client.id, authContext.itemId, name, email, address, contact1, contact2, contactName, tag, loc, contact, description, pincode, localarea, state, district, category, country, status, site_url, hide_menu, hide_footer, erp_branchid, is_college);

    }

    const onImagesSelected = (pictures) => {
        setSelectedImages(pictures);
        //  console.log(pictures);
        if (pictures.length > 0) setUploadButtonDisabled(false); else setUploadButtonDisabled(true);
    }

    const renderImageUploader = () => {
        if (hasImages) return <ImageUploader onImagesSelected={onImagesSelected} maxWidthOrHeight='400' pictures={selectedImages} />
        else return '';
    }

    const renderVideoUploader = () => {
        if (hasVideos) return <Input label='Video Youtube Embed Link' value={videoLink} name='videolink' minLength={5} maxLength={255} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Youtube Video Embed Link' onChange={onVideoLinkChangedHandler} />
        else return '';
    }

    const onImagesUploadHandler = async () => {
        const count = selectedImages.length;
        let counter = 0;
        const preImagesCount = images.length;

        const imagesNowAllowed = maxImagesAllowed - preImagesCount;

        if (count > imagesNowAllowed) { alert('You can now upload a maximum of ' + imagesNowAllowed + ' images.'); return }

        for await (const image of selectedImages) {
            ++counter;
            const formData = new FormData();
            formData.append('itemId', coreContext.client.id);
            formData.append('image', image, image.name);
            formData.append('userId', authContext.userId);
            coreContext.uploadImage(formData, coreContext.client.id);

            if (counter === count) {
                setTimeout(() => {
                    setSelectedImages([]);
                    coreContext.fetchImages(coreContext.client.id);
                }, 2000);
            };
        }



    }

    const onVideosUploadHandler = async () => {
        await coreContext.uploadVideoLink(videoLink, coreContext.client.id, authContext.userId);
        setTimeout(() => {
            coreContext.fetchVideos(coreContext.client.id);
        }, 2000);

    }

    const onCountryChangedHandler = (e) => {
        coreContext.fetchStates(e.target.value);
    }

    const onStateChangedHandler = (e) => {
        const co = getValues("country");
        coreContext.fetchDistricts(co, e.target.value);
    }


    const onVideoLinkChangedHandler = (e) => {
        setVideoLink(e.target.value);
        const url = e.target.value;
        if (url.length > 5) setVideoUploadButtonDisabled(false); else setVideoUploadButtonDisabled(true);
    }

    const onImageChangedHandler = (e) => {
        if (hasImages) {
            setHasImages(false);
            coreContext.updateHasImage(authContext.itemId, coreContext.client.id, 'no');
        }
        else {
            setHasImages(true);
            coreContext.updateHasImage(authContext.itemId, coreContext.client.id, 'yes');
        }

        setSelectedImages([]);
        setUploadButtonDisabled(true);
    }

    const onVideoChangedHandler = (e) => {
        if (hasVideos) {
            setHasVideos(false);
            coreContext.updateHasVideo(authContext.itemId, coreContext.client.id, 'no');
        }
        else {
            setHasVideos(true);
            coreContext.updateHasVideo(authContext.itemId, coreContext.client.id, 'yes');
        }
        setVideoUploadButtonDisabled(true);
    }



    const onHideMenuChangedHandler = (e) => {

        if (hideMenu === 'no') { setHideMenu('yes'); setValue('hide_menu', 'yes'); }
        else { setHideMenu('no'); setValue('hide_menu', 'no'); }
    }
    const onHideFooterChangedHandler = (e) => {
        if (hideFooter === 'no') { setHideFooter('yes'); setValue('hide_footer', 'yes'); }
        else { setHideFooter('no'); setValue('hide_footer', 'no'); }
    }



    return (<div className='container'>
        <div className="row" >
            <div className="col-md-6">
                <div className="card">
                    <div className="card-title mx-auto"><h3><Badge variant="success">Modify Client</Badge></h3></div>
                    <div className="card-body">
                        <Link style={{ position: 'absolute', right: '10px' }} className="btn btn-link" to={`/manage-client/${coreContext.client.id}/${coreContext.client.item_email}`} title="Manage User">Manage User</Link>
                        <Form autoComplete='off' onSubmit={handleSubmit(clientEditHandler)} noValidate>
                            <Input label='Name' name='name' minLength={5} maxLength={55} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Name' />

                            <Input label='Website URL' name='site_url' minLength={2} maxLength={255} required={false} register={register} errors={errors} elementType='textarea' placeholder='Website URL' />

                            <Input label='ERP Branch ID' name='erp_branchid' minLength={4} maxLength={55} required={false} register={register} errors={errors} elementType='textarea' placeholder='ERP Branch ID' />

                            <Input label='Is a College' name='is_college' required={true} register={register} errors={errors} elementType='select' options={isCollegeOptions} />

                            <Input label='Title' name='tag' minLength={5} maxLength={555} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Site Title . eg: "Siddhanta Technnology Services || The best School ERP provider || best Mobile App provider" ...Google use this title for SEO' />

                            <Input label='Meta Description' name='description' minLength={5} maxLength={555} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Meta Description ... eg. "We are the best school in Muzaffarpur."  ...Google use these descriptions for SEO.' />

                            <Input label='LOC' name='log' minLength={2} maxLength={555} required={false} register={register} errors={errors} elementType='textarea' placeholder='Google Map Embed Code  from map.google.com' />

                            <Input label='Contact Person Name' name='contactName' minLength={2} maxLength={55} required={false} register={register} errors={errors} elementType='text' placeholder='Contact Person Name' />

                            <Input label='Contact' name='contact' minLength={10} maxLength={20} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Contact No.' />

                            <Input label='Mobile-I' name='contact1' minLength={10} maxLength={10} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Contact No 1.' />

                            <Input label='Mobile-II' name='contact2' minLength={10} maxLength={10} required={false} register={register} errors={errors} elementType='text' placeholder='Enter Contact No 2.' />

                            <Input label='Email' name='email' required={true} register={register} errors={errors} elementType='text' readOnly={true} minLength={5} maxLength={55} pattern={/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/} placeholder='Enter Email' />

                            {/* <Input label='Reset Password' required={false} register={register} errors={errors} name='password' minLength={5} maxLength={55} elementType='password' placeholder='Reset Password' onChange={onPasswordChangedHandler} /> */}

                            <Input label='Address' name='address' minLength={5} maxLength={155} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Address' />

                            <Input label='Pincode' name='pincode' minLength={6} maxLength={6} required={false} register={register} errors={errors} elementType='text' placeholder='Enter Pincode' />

                            <Input label='Status' name='status' required={true} register={register} errors={errors} elementType='select' options={statusOptions} />

                            <Input label='Category' name='category' required={true} register={register} errors={errors} elementType='select' options={coreContext.categoryOptions} />

                            <Input label='Country' name='country' required={true} register={register} errors={errors} elementType='select' options={coreContext.countryOptions} onChange={onCountryChangedHandler} />

                            <Input label='State' name='state' required={true} register={register} errors={errors} elementType='select' options={coreContext.stateOptions} onChange={onStateChangedHandler} />

                            <Input label='District' name='district' required={true} register={register} errors={errors} elementType='select' options={coreContext.districtOptions} />

                            <Input label='Local Area' name='localarea' required={false} register={register} errors={errors} elementType='select' options={coreContext.localAreaOptions} />

                            <div className='row'>
                                <div className="col-md-6"> <Input elementType='checkbox' value="Hide Menu" name='hide_menu' checked={hideMenu === 'yes'} onChange={onHideMenuChangedHandler} /></div>
                                <div className="col-md-6"><Input name='hide_footer' elementType='checkbox' value="Hide Footer" checked={hideFooter === 'yes'} onChange={onHideFooterChangedHandler} /></div>
                            </div>

                            <Input elementType='button' variant='primary' value="Update" /> &nbsp;&nbsp; {coreContext.renderLoader()}
                            <Input variant='danger' label={coreContext.message} elementType='label' />
                        </Form>
                    </div>
                </div>
            </div>
            <div className="col-md-6" style={{ maxHeight: '500px', overflowY: 'auto', position: 'sticky', top: '50px' }}>
                <div className="card">
                    <div className="card-header">
                        <Input elementType='checkbox' value="Images" checked={hasImages} onChange={onImageChangedHandler} />
                    </div>
                    <div className="card-body">
                        {renderImageUploader()}
                        <Input value='Upload Images' onClick={onImagesUploadHandler} disabled={uploadButtonDisabled} elementType='button' variant='primary' />&nbsp;&nbsp; {coreContext.renderLoader()}
                        <Input variant='danger' label={coreContext.message} elementType='label' />
                    </div>

                </div>
                <div className="card">
                    <div className="card-header">All Images</div>
                    <div className="card-body">
                        <div className="row">
                            {renderImages()}
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header">
                        <Input elementType='checkbox' value="You Tube Videos" checked={hasVideos} onChange={onVideoChangedHandler} />
                    </div>
                    <div className="card-body">
                        {renderVideoUploader()}
                        <Input value='Upload Video Links' onClick={onVideosUploadHandler} disabled={videoUploadButtonDisabled} elementType='button' variant='primary' />&nbsp;&nbsp; {coreContext.renderLoader()}
                        <Input variant='danger' label={coreContext.message} elementType='label' />
                    </div>
                    <div className="card">
                        <div className="card-header">All Videos</div>
                        <div className="card-body">
                            <div className="row">
                                {renderVideos()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> </div>);
}


export { Client };