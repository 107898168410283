/* eslint-disable no-multi-str */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import ImageUploader from './common/UploadImage';
import Axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import Input from './common/Input';
import { Badge, Form } from 'react-bootstrap';
import { XSquare } from 'react-bootstrap-icons';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { useForm } from "react-hook-form";
import { SinglePageImage } from './SinglePageImage';

const SinglePage = (props) => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);
    const [selectedImages, setSelectedImages] = useState([]);
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
    const [page, setPage] = useState({});
    const [content, setContent] = useState('');
    const [images, setImages] = useState([]);
    const [isGallery, setIsGallery] = useState(false);
    const [showInMenu, setShowInMenu] = useState(false);
    const [colSizeOptions] = useState([{ name: '1', value: '1' }, { name: '2', value: '2' }, { name: '3', value: '3' }, { name: '4', value: '4' }, { name: '5', value: '5' }, { name: '6', value: '6' }, { name: '7', value: '7' }, { name: '8', value: '8' }, { name: '9', value: '9' }, { name: '10', value: '10' }, { name: '11', value: '11' }, { name: '12', value: '12' }]);

    const [pageTypeOptions] = useState([{ name: 'Default', value: '' }, { name: 'Contact Us', value: 'contact-us' }, { name: 'News/Blogs/Cirurlars', value: 'news' }])
    const navigate = useNavigate();
    const location = useLocation();

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({});

    const fetchClient = () => {

        const url = location.pathname;
        Axios.get(url, { params: { itemId: authContext.itemId } }).then(response => {
            const page = response.data.page;
            console.log('pr', page);
            setPage(page);
            setValue('message', page.message);
            setValue('menu', page.pcategory);
            setValue('col_size', page.row_col);
            setValue('page_type', page.page_type);
            setValue('mkword', page.meta_key_word);
            setValue('mkdescription', page.meta_description);
            setValue('slug', page.slug);
            setValue('title', page.title);
            setContent(page.pageContent);
            setImages(page.page_images);

            if (page.images === 'yes')
                setIsGallery(true);
            else setIsGallery(false);

            if (page.show_in_menu === 'yes')
                setShowInMenu(true);
            else setShowInMenu(false);

        }).catch(err => {

            coreContext.checkLogOut(err);
        });

        coreContext.fetchMenu(authContext.itemId);

    }



    useEffect(fetchClient, []);


    const renderImages = () => {
        if (images)
            return images.map(image => <SinglePageImage key={image.id} image={image} />)
    }

    const onImagesUploadHandler = async () => {
        const count = selectedImages.length;
        let counter = 0;

        for await (const image of selectedImages) {
            ++counter;

            const formData = new FormData();
            formData.append('itemId', authContext.itemId);
            formData.append('pageId', page.id);
            formData.append('image', image, image.name);
            formData.append('userId', authContext.userId);
            coreContext.uploadPageImage(formData, authContext.itemId, page.id);
            if (counter === count) {
                setTimeout(() => {
                    setSelectedImages([]);
                    // coreContext.fetchPageImages(authContext.itemId, page.id);
                    fetchClient();
                }, 2000);
            };
        }
    }


    const onGalleryChangedHandler = (e) => {
        if (isGallery) {
            setIsGallery(false);
            coreContext.updateIsPageGallery(authContext.itemId, page.id, 'no');
        }
        else {
            setIsGallery(true);
            coreContext.updateIsPageGallery(authContext.itemId, page.id, 'yes');
        }
    }

    const onSIMChangedHandler = (e) => {
        if (showInMenu) {
            setShowInMenu(false);
            coreContext.showInMenu(authContext.itemId, page.id, 'no');
        }
        else {
            setShowInMenu(true);
            coreContext.showInMenu(authContext.itemId, page.id, 'yes');
        }
    }


    const handleEditorChange = (content, editor) => {
        setContent(content);
    }


    const onPageContentHandler = (data) => {
        console.log(data);
        coreContext.updatePageContent(data.title, data.message, content, data.slug, authContext.itemId, page.id, data.col_size, data.mkword, data.mkdescription, data.page_type, data.menu);

    }

    const onImagesSelected = (pictures) => {

        setSelectedImages(pictures);
        if (pictures.length > 0) setUploadButtonDisabled(false); else setUploadButtonDisabled(true);
    }

    return (<div className='container-fluid'>
        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Edit Page</Badge></h3></div>
            <div className="card-body">
                <Form autoComplete='off' onSubmit={handleSubmit(onPageContentHandler)} noValidate>
                    <div className="row">
                        <div className="col-md-3"> <Input label='Menu' name='menu' required={true} register={register} errors={errors} elementType='select' options={coreContext.menu} /></div>
                        <div className="col-md-3">
                        <Input label='Title' name='title' minLength={2} maxLength={155} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Title' /> </div>
                        <div className="col-md-3">
                            <Input label='Meta Key Word' name='mkword' minLength={2} maxLength={555} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Meta Key Word' /> </div>
                        <div className="col-md-3">
                            <Input label='Meta Description' name='mkdescription' minLength={2} maxLength={555} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Meta Description' /> </div>
                    </div>
                    <div className="row"><div className="col-md-4">
                        <Input label='Slug' name='slug' minLength={2} maxLength={155} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Slug' />
                    </div>
                        <div className="col-md-4">
                            <Input elementType='checkbox' value="Does this Page has an Image Gallery? " checked={isGallery} onChange={onGalleryChangedHandler} />

                            <br />
                            <Input elementType='checkbox' value="Show in Menu? " checked={showInMenu} onChange={onSIMChangedHandler} />

                        </div>
                        <div className="col-md-2">
                            {isGallery ? <Input label='Gallery Column Size' name='col_size' required={true} register={register} errors={errors} elementType='select' options={colSizeOptions} /> : null}
                        </div>
                        <div className="col-md-2">
                            <Input label='Page Type' name='page_type' register={register} errors={errors} elementType='select' options={pageTypeOptions} />
                        </div>
                    </div>
                    <Input label='Image Message' name='message' minLength={2} maxLength={2000} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Message' />

                    <Editor
                        apiKey={coreContext.key2}
                        value={content}
                        init={{
                            height: 500,
                            menubar: 'insert',
                            file_picker_types: 'file image media',
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                            ],
                            toolbar:
                                'undo redo | formatselect | bold italic backcolor forecolor  | fontsize fontfamily lineheight  | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent image | removeformat | code  | help'
                        }}
                        onEditorChange={handleEditorChange}
                    />

                    <Input elementType='button' variant='primary' value="Update" /> &nbsp;&nbsp; {coreContext.renderLoader()}
                    <Input variant='danger' label={coreContext.message} elementType='label' />

                </Form>
            </div>
        </div>
        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Upload Page Images</Badge></h3></div>
            <div className="card-title mx-auto">Best Image Size 500*333</div>
            <div className="card-body">
                <ImageUploader onImagesSelected={onImagesSelected} maxWidthOrHeight='500' pictures={selectedImages} />
                <Input value='Upload Images' onClick={onImagesUploadHandler} disabled={uploadButtonDisabled} elementType='button' variant='primary' />&nbsp;&nbsp; {coreContext.renderLoader()}
                <Input variant='danger' label={coreContext.message} elementType='label' />
            </div>
            <div className="card-body">
                <div className="card-title mx-auto"><h3><Badge variant="success">Page Images</Badge></h3></div>
                <div className="row">
                    {renderImages()}
                </div>
            </div>
        </div>
    </div>
    )
}

export { SinglePage }