/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';

import Input from './common/Input';
import { Form, Badge } from 'react-bootstrap';
import { XSquare } from 'react-bootstrap-icons';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { useForm } from "react-hook-form";
import { useLocation, useParams } from 'react-router-dom';
import Axios from 'axios';

const SingleUser = (props) => {

    const [userEmail, setUserEmail] = useState('');
    const [userId, setUserId] = useState('');

    const [user, setUser] = useState({});

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);

    const location = useLocation();
    let { id } = useParams();

    const fetchClient = () => {

        setUserId(id);
        Axios.get(location.pathname, { params: { itemId: authContext.itemId } }).then(response => {

            console.log('user', response.data.user);
            setUserEmail(response.data.user.email);
            setUser(response.data.user);
        }).catch(err => {
            //coreContext.checkLogOut(err);
        });
    }

    useEffect(fetchClient, []);
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({});
    // const onSubmit = data => console.log(data);

    const onUserPasswordChangedHandler = (data) => {
        Axios.post('reset-password', { email: userEmail, password: data.password }).then(res => {
            alert('Password reset successfully done');
        });

    }

    const onUserDeleteHandler = () => {
        Axios.post('delete-user', { id: userId, ownerId: authContext.itemId }).then(res => {
            fetchClient();
            alert('User deleted successfully');
        });

    }
    const onUserRestoreHandler = () => {
        Axios.post('restore-user', { id: userId, ownerId: authContext.itemId }).then(res => {
            fetchClient();
            alert('User restored successfully');
        });

    }

    const onUserDeleteConfirmHandler = () => {
        if (confirm('Are you sure you want to delete this user?'))
            onUserDeleteHandler();

    }


    return (
        <div className='container-fluid'>
            <div className="card">
                <div className="card-title mx-auto"><h3><Badge variant="success">Manage User</Badge></h3></div>
                <div className="card-title mx-auto"><h3><Badge bg="dark">{userEmail}</Badge></h3></div>
                <div className="card-body">
                    <Form autoComplete='off' onSubmit={handleSubmit(onUserPasswordChangedHandler)} noValidate>
                        <div className="row">
                            <div className="col-md-3">
                                <Input name='password' minLength={5} maxLength={50} required={true} register={register} errors={errors} elementType='text' placeholder='Enter new password' />
                            </div>
                            <div className="col-md-1"> <Input elementType='button' variant='primary' value="Reset" /></div>
                        </div>
                    </Form> </div>

                <div className="card-body">

                    <div className="row">
                        <div className="col-md-2">
                            {user.status === 'active' ? <Input onClick={onUserDeleteConfirmHandler} elementType='button' variant='danger' value="Delete User" />
                                : <Input onClick={onUserRestoreHandler} elementType='button' variant='success' value="Restore User" />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { SingleUser }