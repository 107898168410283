import React, { useContext, useEffect } from 'react';
import { Badge } from 'react-bootstrap';
import { Eye } from 'react-bootstrap-icons';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { Link, useNavigate } from 'react-router-dom';

const Users = (props) => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);


    const navigate = useNavigate();

    const redirect = () => {
        if (authContext.userId !== authContext.itemId) { navigate('/'); alert('Action Not Authorized !'); }
    }

    useEffect(redirect, []);

    const fetchUsers = () => {

        coreContext.fetchUsers(authContext.itemId);
    }

    useEffect(fetchUsers, []);


    const renderUsers = () => {
        if (coreContext.users) {
            return coreContext.users.map((user, index) => (
                <tr key={++index}>
                    <td>{index}</td>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.contact1}</td>
                    <td>{user.contact2}</td>
                    <td>{user.status}</td>
                    <td><Link className="btn btn-primary" to={`/user/${user.id}`} title="Manage User"><Eye size="12" /></Link></td>
                </tr>
            )); 
        }
        else {
            return (<tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>)
        }
    }

    return (

        <div className="card">
            <div className="card-title mx-auto">
                <h3>
                    <Badge variant="success">User List</Badge>
                </h3>
            </div>
            <div className="card-body table-responsive">
                <table className='table table-sm table-bordered'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Contact1</th>
                            <th>Contact2</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderUsers()}
                    </tbody>
                </table>
            </div>
        </div>

    );

}


export { Users };