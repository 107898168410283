/* eslint-disable no-multi-str */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import ImageUploader from './common/UploadImage';
import { Editor } from '@tinymce/tinymce-react';
import { useNavigate, useLocation } from 'react-router-dom';
import Axios from 'axios';
import Input from './common/Input';
import { Badge, Form } from 'react-bootstrap';
import { XSquare } from 'react-bootstrap-icons';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { useForm } from "react-hook-form";


const SingleEvent = (props) => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);
    const location = useLocation();

    const [selectedImages, setSelectedImages] = useState([]);
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [content, setContent] = useState('');
    const [images, setImages] = useState([]);
    const [edate, setEdate] = useState('');
    const [etime, setEtime] = useState('');
    const [imageTitle, setImageTitle] = useState('');
    const [imageMessage, setImageMessage] = useState('');

    const navigate = useNavigate();
    const fetchClient = () => {
        coreContext.fetchEvent(location, authContext.itemId);
    }

    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    useEffect(fetchClient, []);

    const onImageMessageChangedHandler = (e) => {
        setImageMessage(e.target.value);
    }

    const onImageTitleChangedHandler = (e) => {
        setImageTitle(e.target.value);
    }

    const populateClient = () => {
        setValue('title', coreContext.event.title);
        setValue('message', coreContext.event.message);
        setContent(coreContext.event.pageContent);
        setValue('edate', coreContext.event.edate);
        setValue('etime', coreContext.event.etime);
        setImages(coreContext.event.event_images);
    }

    useEffect(populateClient, [coreContext.event]);

    const deleteImage = (id) => {
        deleteEventImage(id);
    }


    const deleteEventImage = (id) => {

        Axios.post('/delete-event-image', { id }).then((response) => { 
            const newImages = images.filter(image => image.id !== id);
            setImages(newImages);
        }).catch(err => { });
    }


    const renderImages = () => {
        if (images)
            return images.map(image => <div style={{ position: 'relative' }} key={image.id} className="col-md-6"><img style={{ padding: 5 }} className="img-fluid" src={`http://${image.url}`} alt='gallery image' /> <button onClick={() => deleteImage(image.id)} style={{ position: 'absolute', top: 0, left: 5 }} type="button" className="btn btn-danger btn-sm" title="Delete Image"> <XSquare size="12" /> </button>

                <Input label='Image Title' value={imageTitle} name='imagtitle' minLength={2} maxLength={155} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Image Title' onChange={(onImageTitleChangedHandler)} />

                <Input label='Image Message' value={imageMessage} name='imagemessage' minLength={2} maxLength={2000} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Image Message' onChange={onImageMessageChangedHandler} />
            </div>)
    }

    const onImagesUploadHandler = async () => {
        const count = selectedImages.length;
        let counter = 0;

        for await (const image of selectedImages) {
            ++counter;
            const formData = new FormData();
            formData.append('itemId', authContext.itemId);
            formData.append('eventId', coreContext.event.id);
            formData.append('image', image, image.name);
            formData.append('userId', authContext.userId);
            coreContext.uploadEventImage(formData, authContext.itemId, coreContext.event.id);

            if (counter === count) {
                setTimeout(() => {
                    setSelectedImages([]);
                    coreContext.fetchEventImages(authContext.itemId, coreContext.event.id);
                }, 2000);

                setTimeout(() => {
                    navigate('/events');
                }, 5000);
            };
        }
    }


    const handleEditorChange = (content, editor) => {
        setContent(content);
    }


    const onEventContentHandler = (data) => {
        coreContext.updateEventContent(data.title, data.message, content, data.edate, data.etime, authContext.itemId, coreContext.event.id);

    }

    const onImagesSelected = (pictures) => {
        setSelectedImages(pictures);
        //  console.log(pictures);
        if (pictures.length > 0) setUploadButtonDisabled(false); else setUploadButtonDisabled(true);
    }

    return (<div className='container-fluid'>
        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Edit Event</Badge></h3></div>
            <div className="card-body">
                <Form autoComplete='off' onSubmit={handleSubmit(onEventContentHandler)} noValidate>
                    <Input label='Title' name='title' minLength={2} maxLength={155} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Title' />

                    <Input label='Image Message' name='message' minLength={2} maxLength={2000} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Message' />

                    <div className="row"><div className="col-md-6">
                        <Input label='Event Date' name='edate' minLength={2} maxLength={50} required={true} register={register} errors={errors} elementType='date' placeholder='Enter Event Date' />
                    </div>
                        <div className="col-md-6">
                            <Input label='Event Time' name='etime' minLength={2} maxLength={50} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Event Time : 11am to 6pm' />
                        </div></div>

                    <Editor
                        apiKey={coreContext.key2}
                        value={content}
                        init={{
                            height: 500,
                            menubar: 'insert',
                            file_picker_types: 'file image media',
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                            ],
                            toolbar:
                                'undo redo | formatselect | bold italic backcolor forecolor  | fontsize fontfamily lineheight | \
         alignleft aligncenter alignright alignjustify | \
         bullist numlist outdent indent image | removeformat | code | help'
                        }}
                        onEditorChange={handleEditorChange}
                    />

                    <Input elementType='button' variant='primary' value="Update" /> &nbsp;&nbsp; {coreContext.renderLoader()}
                    <Input variant='danger' label={coreContext.message} elementType='label' />

                </Form>
            </div>
        </div>
        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Upload Event Images</Badge></h3></div>
            <div className="card-title mx-auto">Best Image Size 500*333</div>
            <div className="card-body">
                <ImageUploader onImagesSelected={onImagesSelected} maxWidthOrHeight='500' pictures={selectedImages} />
                <Input value='Upload Images' onClick={onImagesUploadHandler} disabled={uploadButtonDisabled} elementType='button' variant='primary' />&nbsp;&nbsp; {coreContext.renderLoader()}
                <Input variant='danger' label={coreContext.message} elementType='label' />
            </div>
            <div className="card-body">
                <div className="card-title mx-auto"><h3><Badge variant="success">Event Images</Badge></h3></div>
                <div className="row">
                    {renderImages()}
                </div>
            </div>
        </div>
    </div>
    )
}

export { SingleEvent }