import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import loader from '../assets/images/835.gif';
import { toast } from 'react-toastify';

export const AuthContext = React.createContext({
    // isAuth: false,
    // message: '',
    // showLoader: false,
    // jwt: '',
    // userId: '',
    // login: () => { },
    // signUp: () => { },
    // addCategory: () => { },
});

const AuthContextProvider = props => {

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [message, setMessage] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [jwt, setJwt] = useState({});
    const [userId, setUserId] = useState('');
    const [itemId, setItemId] = useState('');
    const [item, setItem] = useState({});
    const [currPassVerified, setCurrentPassVerified] = useState(false);


    const checkLocalAuth = () => {
        const isAuth = localStorage.getItem('app_isAuth');
        const token = localStorage.getItem('app_jwt');
        const userId = localStorage.getItem('app_userId');
        const itemId = localStorage.getItem('app_itemId');
        const item = JSON.parse(localStorage.getItem('app_item'));


        if (isAuth === 'yes') {
            setIsAuthenticated(true);
            setJwt(token);
            setUserId(userId);
            setItemId(itemId);
            setItem(item);
        }
        else {
            setIsAuthenticated(false);

        }
    }

    useEffect(checkLocalAuth, []);

    const notify = (message) => toast(message);


    const signUpHandler = (name, email, password) => {
        setShowLoader(true);
        Axios.post('/sign-up', { email, password, name, userId, itemId }).then(response => {
            setShowLoader(false);
            // console.log('after signup', response.data);
            const value = response.data.message;
            setMessage(value);
            createPages(response.data.data.itemId, response.data.data.userId);

        }).catch(err => {
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        })
    }

    const createPages = (itemId, userId) => {

        // console.log('before page create', itemId, userId);

        Axios.post('/create-pages', { itemId, userId });
    }

    const join = (name, email, password) => {
        setShowLoader(true);
        Axios.post('/join', { email, password, name, userId, itemId }).then(response => {
            setShowLoader(false);
            // console.log(response.data);
            const value = response.data.message;
            setMessage(value);
            //  window.location = '/';

            loginHandlerJoin(email, password);

        }).catch(err => {
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        })
    }

    const addUser = (name, email, password, role, contact1, contact2) => {
        setShowLoader(true);
        Axios.post('/add-user', { email, password, name, role, userId: itemId, contact1, contact2 }).then(response => {
            setShowLoader(false);
            // console.log(response.data);
            const value = response.data.message;
            setMessage(value);
        }).catch(err => {
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        })
    }



    const renderLoader = () => {
        if (showLoader) return (<span><img src={loader} alt="" /></span>);
        else return <span></span>
    }

    const addCategory = (name) => {
        console.log(name);

    }
    const logOutHandler = () => {
        localStorage.removeItem('app_isAuth');
        localStorage.removeItem('app_jwt');
        localStorage.removeItem('app_userId');
        localStorage.removeItem('app_itemId');
        localStorage.removeItem('app_item');
        setIsAuthenticated(false);

        // window.location = '/';

    }

    const verifyCurrentPassword = (email, password, newPassword) => {
        Axios.post('/verify-current-password', { email, password, newPassword }).then(response => {
            setCurrentPassVerified(true);
            setMessage('');
            if (newPassword) notify('Password reset successfully !')
        }).catch(err => {
            setMessage('Error! Wrong password entered');
            setCurrentPassVerified(false);
        })
    }

    const loginHandler = (email, password, url) => {
        // setIsAuthenticated(true);
        // console.log(email, password);
        setShowLoader(true);
        Axios.post('/login', { email, password, url }).then(response => {
            setIsAuthenticated(true);
            setShowLoader(false);
            setMessage('');
            // console.log('jwt', response.data);
            const { token, userId, itemId, item } = response.data;
            setJwt(token);
            setUserId(userId);
            setItemId(itemId);
            setItem(item);
            // console.log('itemId', itemId);
            localStorage.setItem('app_isAuth', 'yes');
            localStorage.setItem('app_jwt', token);
            localStorage.setItem('app_userId', userId);
            localStorage.setItem('app_itemId', itemId);
            localStorage.setItem('app_item', JSON.stringify(item));
            //  window.location.assign(props.match.url);
            //window.location.assign(url);


        }).catch(err => {
            const value = err.response.data.message;
            setMessage(value);
            setShowLoader(false);
        });
    }

    const loginHandlerJoin = (email, password) => {
        // setIsAuthenticated(true);
        // console.log(email, password);
        setShowLoader(true);
        Axios.post('/login', { email, password }).then(response => {
            setIsAuthenticated(true);
            setShowLoader(false);
            setMessage('');
            // console.log('jwt', response.data);
            const { token, userId, itemId, item } = response.data;
            setJwt(token);
            setUserId(userId);
            setItemId(itemId);
            setItem(item);
            // console.log('itemId', itemId);
            localStorage.setItem('app_isAuth', 'yes');
            localStorage.setItem('app_jwt', token);
            localStorage.setItem('app_userId', userId);
            localStorage.setItem('app_itemId', itemId);
            localStorage.setItem('app_item', JSON.stringify(item));

            window.location = '/self';

        }).catch(err => {
            const value = err.response.data.message;
            setMessage(value);
            setShowLoader(false);
        });
    }

    return (
        <AuthContext.Provider value={{
            isAuth: isAuthenticated,
            showLoader: showLoader,
            message: message,
            jwt: jwt,
            userId: userId,
            itemId: itemId,
            signUp: signUpHandler,
            login: loginHandler,
            logOut: logOutHandler,
            renderLoader,
            addCategory,
            addUser,
            item,
            join,
            currPassVerified,
            verifyCurrentPassword
        }}>
            {props.children}
        </AuthContext.Provider >
    );
}


export default AuthContextProvider;